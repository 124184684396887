import { Str } from "../../../lib/web/core/str";
import { DictionaryObject } from "../../../lib/web/core/types";
import { Injectable } from "../../../lib/web/reflection/injectable";
import { TooltipService } from "./tooltip.service";

@Injectable({ type: 'singleton' })
export class ValidationService {

    public constructor(private _tooltipService: TooltipService) {        
    }

    public validateRegister(
            fields: { name: string, surname1: string, surname2: string, gender: string, birthDate: Date, country: number, postalCode: string, language: string, email: string, repeatEmail: string, password: string, repeatPassword: string, privacyDataAccepted: boolean, termsAccepted: boolean, commercialAccepted: boolean },
            data: { name: string, surname1: string, surname2: string, gender: string, birthDate: Date, country: number, postalCode: string, language: string, email: string, repeatEmail: string, password: string, repeatPassword: string, privacyDataAccepted: boolean, termsAccepted: boolean, commercialAccepted: boolean }
    ): DictionaryObject<string[]> {
        const { name, surname1, surname2, gender, birthDate, country, postalCode, language, email, repeatEmail, password, repeatPassword, privacyDataAccepted, termsAccepted, commercialAccepted } = fields;
        const errors: DictionaryObject<string[]> = this.getEmptyErrors(fields);
        if (name !== undefined) {
            errors.name = this.validateString(name, { mandatory: true, minLength: 2, maxLength: 75, type: 'name' });
        }
        if (surname1 !== undefined) {
            errors.surname1 = this.validateString(surname1, { mandatory: true, minLength: 2, maxLength: 75, type: 'name' });
        }
        if (surname2 !== undefined) {
            errors.surname2 = this.validateString(surname2, { mandatory: false, minLength: 2, maxLength: 75, type: 'name' });
        }
        if (gender !== undefined) {
            errors.gender = this.validateString(gender, { mandatory: true });
        }
        if (birthDate !== undefined) {
            errors.birthDate = this.validateDate(birthDate, { mandatory: true, minAge: 14 });
        }
        if (postalCode !== undefined) {
            errors.postalCode = this.validateString(postalCode, { mandatory: data.country == CONFIG.SPAIN_COUNTRY_ID || data.country == CONFIG.PORTUGAL_COUNTRY_ID, type: data.country == CONFIG.SPAIN_COUNTRY_ID 
                ? 'postalCodeES'
                : data.country == CONFIG.PORTUGAL_COUNTRY_ID
                    ? 'postalCodePT'
                    : null
            });
        }
        if (language !== undefined) {
            errors.language = this.validateString(language, { mandatory: true });
        }     
        if (email !== undefined) {
            errors.email = this.validateString(email, { mandatory: true, type: 'email' });
        }
        if (repeatEmail !== undefined) {
            errors.repeatEmail = this.validateString(repeatEmail, { mandatory: true, type: 'email' });
            if (repeatEmail && repeatEmail != data.email) {
                errors.repeatEmail.push(USER_LOCALE.emailsNotEqualsError);
            }
        }
        if (password !== undefined) {
            errors.password = this.validateString(password, { mandatory: true,  minLength: 8, maxLength: 16, type: 'password' });
        }
        if (repeatPassword !== undefined) {
            errors.repeatPassword = this.validateString(repeatPassword, { mandatory: true, minLength: 8, maxLength: 16, type: 'password' });
            if (repeatPassword && repeatPassword != data.password) {
                errors.repeatPassword.push(USER_LOCALE.passwordsNotEqualsError);
            }
        }
        if (privacyDataAccepted !== undefined) {
            errors.privacyDataAccepted = this.validateBoolean(privacyDataAccepted, { mandatory: true });
        }
        if (termsAccepted !== undefined) {
            errors.termsAccepted = this.validateBoolean(termsAccepted, { mandatory: true });
        }
        if (commercialAccepted !== undefined) {
            errors.commercialAccepted = this.validateBoolean(commercialAccepted, { mandatory: false });
        }
        return errors;
    }

    public validateRenewPassword(fields: { password: string, repeatPassword: string }, data: { password: string, repeatPassword: string }): DictionaryObject<string[]> {
        const { password, repeatPassword } = fields;
        const errors: DictionaryObject<string[]> = this.getEmptyErrors(fields);        
        if (password !== undefined) {
            errors.password = this.validateString(password, { mandatory: true,  minLength: 8, maxLength: 16, type: 'password' });
        }
        if (repeatPassword !== undefined) {
            errors.repeatPassword = this.validateString(repeatPassword, { mandatory: true, minLength: 8, maxLength: 16, type: 'password' });
            if (repeatPassword && repeatPassword != data.password) {
                errors.repeatPassword.push(USER_LOCALE.passwordsNotEqualsError);
            }
        }
        return errors;
    }

    public validateChangePassword(fields: { currentPassword: string, password: string, repeatPassword: string }, data: { currentPassword: string, password: string, repeatPassword: string }): DictionaryObject<string[]> {
        const { currentPassword, password, repeatPassword } = fields;
        const errors: DictionaryObject<string[]> = this.getEmptyErrors(fields);        
        // if (currentPassword !== undefined) {
        //     errors.currentPassword = this.validateString(currentPassword, { mandatory: true,  minLength: 8, maxLength: 16, type: 'password' });
        // }
        if (password !== undefined) {
            errors.password = this.validateString(password, { mandatory: true,  minLength: 8, maxLength: 16, type: 'password' });
        }
        if (repeatPassword !== undefined) {
            errors.repeatPassword = this.validateString(repeatPassword, { mandatory: true, minLength: 8, maxLength: 16, type: 'password' });
            if (repeatPassword && repeatPassword != data.password) {
                errors.repeatPassword.push(USER_LOCALE.passwordsNotEqualsError);
            }
        }
        return errors;
    }

    public validateSignIn(fields: { email: string, password: string }, data: { email: string, password: string }): DictionaryObject<string[]> {
        const { email, password } = fields;
        const errors: DictionaryObject<string[]> = this.getEmptyErrors(fields);        
        if (email !== undefined) {
            errors.email = this.validateString(email, { mandatory: true, type: 'email' });
        }
        // if (password !== undefined) {
        //     errors.password = this.validateString(password, { mandatory: true,  minLength: 8, maxLength: 16, type: 'password' });
        // }
        return errors;
    }

    public validateProfileData(
        fields: { name: string, surname1: string, surname2: string, gender: string, birthDate: Date },
        data: { name: string, surname1: string, surname2: string, gender: string, birthDate: Date }
    ): DictionaryObject<string[]> {
        const { name, surname1, surname2, gender, birthDate } = fields;
        const errors: DictionaryObject<string[]> = this.getEmptyErrors(fields);
        if (name !== undefined) {
            errors.name = this.validateString(name, { mandatory: true, minLength: 2, maxLength: 75, type: 'name' });
        }
        if (surname1 !== undefined) {
            errors.surname1 = this.validateString(surname1, { mandatory: true, minLength: 2, maxLength: 75, type: 'name' });
        }
        if (surname2 !== undefined) {
            errors.surname2 = this.validateString(surname2, { mandatory: false, minLength: 2, maxLength: 75, type: 'name' });
        }
        if (gender !== undefined) {
            errors.gender = this.validateString(gender, { mandatory: true });
        }
        if (birthDate !== undefined) {
            errors.birthDate = this.validateDate(birthDate, { mandatory: true, minAge: 14 });
        }        
        return errors;
    }
    
    public validatePostalCode(fields: { postalCode: string }, data: { country: number }): DictionaryObject<string[]> {
        const { postalCode } = fields;
        const errors: DictionaryObject<string[]> = this.getEmptyErrors(fields);   
        if (postalCode !== undefined) {
            errors.postalCode = this.validateString(postalCode, { mandatory: data.country == CONFIG.SPAIN_COUNTRY_ID || data.country == CONFIG.PORTUGAL_COUNTRY_ID, type: data.country == CONFIG.SPAIN_COUNTRY_ID 
                ? 'postalCodeES'
                : data.country == CONFIG.PORTUGAL_COUNTRY_ID
                    ? 'postalCodePT'
                    : null
            });
        }
        return errors;
    }
    
    private validateString(value: string, options: { mandatory?: boolean, minLength?: number, maxLength?: number, type?: 'name' | 'email' | 'password' | 'postalCodeES' | 'postalCodePT' }): string[] {
        const { mandatory, minLength, maxLength, type } = options;
        const errors: string[] = [];
        if (mandatory && (!value || !value.trim())) {        
            errors.push(type == 'password' ? USER_LOCALE.passwordFormatWebError: USER_LOCALE.mandatoryFieldError);
        }
        if (value && minLength && value.length < minLength) {
            errors.push(type == 'password' ? USER_LOCALE.passwordFormatWebError: USER_LOCALE.minLengthFieldError.format(minLength));
        }      
        if (value && maxLength && value.length > maxLength) {
            errors.push(type == 'password' ? USER_LOCALE.passwordFormatWebError: USER_LOCALE.maxLengthFieldError.format(maxLength));
        } 
        if (value && type == 'name') {
            if (!/^[\-/A-Za-z\u00C0-\u017F ]+$/.test(value)) {
                errors.push(USER_LOCALE.onlyLettersAndSpaceError);
            }            
        }
        if (value && type == 'email') {
            if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
                errors.push(USER_LOCALE.emailFormatError);
            }            
        }
        if (value && type == 'password') {
            if (!/^(?=.*[a-zA-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!~<>?=_+,;:@#.$%()\/|\\[\]\^&\*])[a-zA-Z0-9!~<>?=_+,;:@#.$%()\/|\\\[\]\^&\*]{8,}$/g.test(value) || !/\d/.test(value)) {
                errors.push(USER_LOCALE.passwordFormatWebError);
            }
            const allowedSpecialCharRegex: RegExp = RegExp('(?=.*[!~<>?=_+,;:@#.$%()\\[\\]\/|\^&\*])', 'g');
            const specialNotAllowedChars: string[] = [];
            let showSpecialError: boolean = false;

            for (let char of value) {
                if (char.match(/[^\w\s]/) && !allowedSpecialCharRegex.test(char)) {
                    specialNotAllowedChars.push(char);
                    showSpecialError = true;
                }
            }

            if (showSpecialError) {
                errors.push(USER_LOCALE.specialCharNotAllowedError.format(Array.from(new Set(specialNotAllowedChars)).map(c => `'${c}'`).join(' ')));
            }
        }
        if (value && type == 'postalCodeES') {
            if (!/\d{5}$/g.test(value)) {
                errors.push(USER_LOCALE.postalCodeFormatError);
            }
        }
        if (value && type == 'postalCodePT') {
            if (value.length != 8 || !/\d{4}$/g.test(value.substring(0, 4)) || value.substring(4, 5) != '-' || !/\d{3}$/g.test(value.substring(5))) {
                errors.push(USER_LOCALE.postalCodeFormatError);
            }
        }
        return [...new Set(errors)];
    }

    private validateDate(value: Date, options: { mandatory?: boolean, minAge?: number }): string[] {
        const { mandatory, minAge } = options;
        const errors: string[] = [];
        if (mandatory && !value) {
            errors.push(USER_LOCALE.mandatoryFieldError);
        }
        if (value && minAge && value.getAge() < 14) {
            errors.push(USER_LOCALE.ageRestrictedAccessError.format(14));
        }
        return errors;
    }

    private validateBoolean(value: boolean, options: { mandatory?: boolean }): string[] {
        const { mandatory } = options;
        const errors: string[] = [];
        if (mandatory && !value) {
            errors.push(USER_LOCALE.mandatoryFieldError);
        }
        return errors;
    }

    public validateAvatar(content: string): string[] {
        const errors: string[] = [];
        if (content) {
            if (Str.getBase64Size(content) > CONFIG.USER_PICTURE_MAX_SIZE) {
                errors.push(USER_LOCALE.userAvatarMaxSize.format(CONFIG.USER_PICTURE_MAX_SIZE / 1000000, 'MB'));
            }
        }
        return errors;
    }

    public formatPostalCode(postalCode: string, data: { country: number }): string {
        if (data.country == CONFIG.PORTUGAL_COUNTRY_ID) {
            if (postalCode && postalCode.length > 3 && postalCode.indexOf('-') < 0) {
                return `${postalCode.substring(0, 4)}-${postalCode.substring(4)}`;
            }
        }
        return postalCode;
    }

    public hasErrors(errors: DictionaryObject<string[]>): boolean {
        return errors && Object.keys(errors).some(k => errors[k] && errors[k].length > 0);
    }

    private getEmptyErrors(data: any): DictionaryObject<string[]> {
        const errors: DictionaryObject<string[]> = {};
        Object.keys(data).forEach(k => errors[k] = []);
        return errors;
    }

    public resetErrors(selector: string, data: any): void {  
        this.showErrors(selector, this.getEmptyErrors(data));
    }

    public showErrors(selector: string, errors: DictionaryObject<string[]>): void {        
        const parentElement: HTMLElement = document.querySelector(selector);
        if (parentElement) {
            Object.keys(errors).forEach(k => {
                const errorElement: HTMLElement = parentElement.querySelector(`.u-error--${k}`);
                const infoElement: HTMLElement = parentElement.querySelector(`.u-field-info--${k}`);
                if (errorElement) {
                    const fieldErrors: string = errors[k].join('<br/>');
                    errorElement.innerHTML = fieldErrors;                    
                    let inputs: HTMLElement[] = Array.from(document.querySelectorAll(`.u-input input[name=${k}]`));
                    if (!inputs || !inputs.length) {
                        inputs = Array.from(document.querySelectorAll(`input[name='${k}']`));
                    }
                    else {
                        inputs = inputs.map(i => i.parentElement);
                    }
                    if (fieldErrors) {
                        infoElement && infoElement.classList.add('u-hidden');
                        errorElement.classList.remove('u-hidden');
                        inputs && inputs.forEach(i => i.classList.add('u-error-input'));
                    }
                    else {
                        infoElement && infoElement.classList.remove('u-hidden');
                        errorElement.classList.add('u-hidden');
                        inputs && inputs.forEach(i => i.classList.remove('u-error-input'));
                    }
                }
            });
        }
        this._tooltipService.fragment(parentElement);
    }
}